import { DirectUpload } from 'activestorage';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';

export default {
  data() {
    return {
      attachedFiles: [],
      resource: null
    }
  },
  methods: {
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter((item, index) => {
        return itemIndex !== index;
      });
    },
    getFileProcessedParameters() {
      if (!this.hasAttachments) return;

      let file = this.attachedFiles[0];
      return {
        type: this.formatTypeTemplateHeader.toLowerCase(),
        path: file.path,
      };
    },
    onFileUpload(file) {
      if (!file) return;

      const MAXIMUM_SIZE = this.acceptedSize;

      if (checkFileSizeLimit(file, MAXIMUM_SIZE)) {
        const endpoint = "/rails/active_storage/direct_uploads";
        const upload = new DirectUpload(file.file, endpoint, {
          directUploadWillCreateBlobWithXHR: xhr => {
            xhr.setRequestHeader("api_access_token",
              this.currentUser.access_token
            );
          },
        });

        upload.create((error, blob) => {
          if (error) {
            this.showAlert(error);
          } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.file);
            reader.onloadend = () => {
              this.attachedFiles.push({
                resource: blob || file,
                isPrivate: false,
                thumb: reader.result,
                blobSignedId: blob ? blob.signed_id : undefined,
                path: blob ? blob.signed_id + '/' + blob.filename : undefined
              });

              this.resource = this.attachedFiles[0].resource;
            };
          }
        });
      } else {
        this.showAlert(this.$t('CONVERSATION.FILE_SIZE_LIMIT', { MAXIMUM_SIZE }));
      }
    },
    processVariable(str) {
      return str.replace(this.getRegexs.location_vars, '');
    },
    hasAnAttach() {
      return this.attachedFiles.length || this.resource;
    }
  },
  computed: {
    hasAttachments() {
      return this.attachedFiles.length;
    },
    acceptedSize() {
      // ref https://developers.facebook.com/docs/whatsapp/on-premises/reference/media/
      if (this.formatTypeTemplateHeader) {
        switch (this.formatTypeTemplateHeader) {
          case "DOCUMENT": return 16000 //16MB
          case "IMAGE": return 500 //5MB
          case "VIDEO": return 16000 //16MB
        }
      }
    },
    acceptedFormats() {
      // ref https://developers.facebook.com/docs/whatsapp/on-premises/reference/media/
      if (this.formatTypeTemplateHeader) {
        switch (this.formatTypeTemplateHeader) {
          case "DOCUMENT": return ".PDF, .DOC"
          case "IMAGE": return ".PNG, .JPEG"
          case "VIDEO": return ".MP4, .3GP"
        }
      }
    },
    getRegexs() {
      return {
        url: /^(https):\/\/[a-zA-Z0-9.-]+([.][a-zA-Z]{2,4})(:[0-9]+)?(\/.*)?$/g,
        words: /\W+/g,
        unicode: / |\B(?=[A-Z])/,
        location_vars: /{{|}}/g,
        vars: /{{([^}]+)}}/g,
        emoji: /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        spaces: /\s+/g
      }
    },
    getTypes() {
      return {
        url: 'URL',
        quickReply: 'QUICK_REPLY',
        phoneNumber: 'PHONE_NUMBER',
      };
    },
  }
};